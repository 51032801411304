(function() {
    'use strict';

    angular.module('common.routes', [])
        .constant('routesConstant', routesConstant());

    function routesConstant() {
        var routes = {},
            loginPrefix = 'login',
            loginUrlPrefix = loginPrefix ? '/' + loginPrefix : '',
            customerPrefix = '',
            customerUrlPrefix = customerPrefix ? '/' + customerPrefix : '',
            mfgPrefix = 'mfg',
            mfgUrlPrefix = mfgPrefix ? '/' + mfgPrefix : '',
            adminPrefix = 'admin',
            adminUrlPrefix = adminPrefix ? '/' + adminPrefix : '',
            linkPrefix = 'link',
            linkUrlPrefix = linkPrefix ? '/' + linkPrefix : '';

        (function init() {
            initLoginRouteConsts(routes);
            initCustomerRouteConsts(routes);
            initAdminRouteConsts(routes);
            initMFGRouteConsts(routes);
            initLinkRouteConsts(routes);
        })();

        function initLoginRouteConsts() {
            routes.LOGIN = {};

            routes.LOGIN.MAIN = setRouteConst(
                loginPrefix,
                loginUrlPrefix
            );

            routes.LOGIN.CUSTOMER = setChildRouteConst(
                routes.LOGIN.MAIN, 'customer', '/customer'
            );

            routes.LOGIN.ADMIN = setChildRouteConst(
                routes.LOGIN.MAIN, 'admin', '/admin'
            );

            routes.LOGIN.MFG = setChildRouteConst(
                routes.LOGIN.MAIN, 'mfg', '/mfg'
            );

            routes.LOGIN.LINK = setChildRouteConst(
                routes.LOGIN.MAIN, 'link', '/link'
            );

            routes.LOGIN.LOGOUT = setRouteConst(
                'logout',
                '/logout/:role'
            );

        }

        function initCustomerRouteConsts(routes) {
            routes.CUSTOMER = {};

            routes.CUSTOMER.DASHBOARD = setRouteConst(
                (customerPrefix ? customerPrefix + '_' : '') + 'dashboard',
                customerUrlPrefix + ''
            );

            // link - this user has an organization and clicked on aeRosLink bar
            routes.CUSTOMER.LINKORG = setChildRouteConst(
                routes.CUSTOMER.DASHBOARD, 'linkorg', '/linkorg'
            );

            // projects

            routes.CUSTOMER.PROJECTS = setChildRouteConst(
                routes.CUSTOMER.DASHBOARD, 'projects', '/projects'
            );

            routes.CUSTOMER.PROJECTS_PROJECT = setChildRouteConst(
                routes.CUSTOMER.DASHBOARD, 'project', '/projects/:id'
            );

            routes.CUSTOMER.PROJECTS_PROJECT_FIBERGROUPS = setChildRouteConst(
                routes.CUSTOMER.PROJECTS_PROJECT, 'fibergroups', '/fibergroups/:qry'
            );

            routes.CUSTOMER.PROJECTS_PROJECT_TEAM = setChildRouteConst(
                routes.CUSTOMER.PROJECTS_PROJECT, 'team', '/team'
            );

            routes.CUSTOMER.PROJECTS_PROJECT_RESULTS = setChildRouteConst(
                routes.CUSTOMER.PROJECTS_PROJECT, 'results',
                '/results/testType/:testType/resultType/:resultType/search/:qry'
            );

            routes.CUSTOMER.PROJECTS_PROJECT_RESULTS_ALL = setChildRouteConst(
                routes.CUSTOMER.PROJECTS_PROJECT_RESULTS, 'all', '/all'
            );

            routes.CUSTOMER.PROJECTS_PROJECT_TRACEVIEWER = setChildRouteConst(
                routes.CUSTOMER.PROJECTS_PROJECT, 'trace-viewer',
                '/results/testType/OTDR/fibergroup/:fiberGroupId/fiber/:fiberId/traceviewer/:fiberName'
            );

            routes.CUSTOMER.PROJECTS_PROJECT_CONTACTS = setChildRouteConst(
                routes.CUSTOMER.PROJECTS_PROJECT, 'contacts', '/contacts'
            );

            routes.CUSTOMER.PROJECTS_PROJECT_REPORT = setChildRouteConst(
                routes.CUSTOMER.PROJECTS_PROJECT, 'report', '/report'
            );

            routes.CUSTOMER.PROJECTS_PROJECT_IMPORT = setChildRouteConst(
                routes.CUSTOMER.PROJECTS_PROJECT, 'import', '/import'
            );

            // organizations

            routes.CUSTOMER.ORGANIZATION = setChildRouteConst(
                routes.CUSTOMER.DASHBOARD, 'org', '/org/:id'
            );

            routes.CUSTOMER.ORGANIZATION_PROFILE = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION, 'profile', '/profile'
            );

            routes.CUSTOMER.ORGANIZATION_USERS = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION, 'users', '/users'
            );

            routes.CUSTOMER.ORGANIZATION_CONTACTS = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION, 'contacts', '/contacts'
            );

            routes.CUSTOMER.ORGANIZATION_TESTSETUPS = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION, 'testsetups', '/testsetups'
            );

            routes.CUSTOMER.ORGANIZATION_TESTLIMITS = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION, 'testlimits', '/testlimits'
            );

            routes.CUSTOMER.ORGANIZATION_TOOLS = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION, 'tools', '/tools'
            );

            routes.CUSTOMER.ORGANIZATION_TOOLS_TYPE = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION_TOOLS, 'type', '/:toolType'
            );

            routes.CUSTOMER.ORGANIZATION_TOOLS_TYPE_TOOLLIBRARIES = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION_TOOLS_TYPE, 'toolLibraries', '/toolLibraries'
            );

            routes.CUSTOMER.ORGANIZATION_TOOLS_TYPE_PRESETS = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION_TOOLS_TYPE, 'presets', '/presets/:presetType'
            );

            routes.CUSTOMER.ORGANIZATION_TOOLS_TYPE_SECTION = setChildRouteConst(
                routes.CUSTOMER.ORGANIZATION_TOOLS_TYPE, 'section', '/list'
            );

        }

        function initAdminRouteConsts(routes) {
            routes.ADMIN = {};

            //main
            routes.ADMIN.MAIN = setRouteConst(
                adminPrefix,
                adminUrlPrefix
            );

            //dashboard
            routes.ADMIN.DASHBOARD = setRouteConst(
                adminPrefix + '_dashboard',
                adminUrlPrefix + '/dashboard'
            );

            //dashboard.organizations
            routes.ADMIN.ORGANIZATIONS = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'organizations', '/organizations'
            );

            //dashboard.organizations.view
            routes.ADMIN.ORGANIZATIONS_VIEW = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'organizations_view', '/organizations/:id'
            );

            //dashboard.userLogging
            routes.ADMIN.USER_LOGGING = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'user_json', '/logging/:orgId?userEmail'
            );

            //dashboard.properties
            routes.ADMIN.PROPERTIES = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'properties', '/properties'
            );

            //dashboard.software
            routes.ADMIN.SOFTWARE = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'software', '/software'
            );

            //dashboard.users
            routes.ADMIN.USERS = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'users', '/users'
            );

            //dashboard.notificationRegistration
            routes.ADMIN.NOTIFICATION_REGISTRATION = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'notificationRegistration', '/notification-registration'
            );

            //dashboard.messages
            routes.ADMIN.MESSAGES = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'messages', '/messages'
            );

            //dashboard.lock
            routes.ADMIN.LOCK = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'lock', '/lock'
            );

            //dashboard.licenses
            routes.ADMIN.LICENSES = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'licenses', '/licenses'
            );

            //dashboard.vendors
            routes.ADMIN.VENDORS = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'vendors', '/vendors'
            );

            //dashboard.features
            routes.ADMIN.FEATURES = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'features', '/features'
            );

            //dashboard.groups
            routes.ADMIN.GROUPS = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'groups', '/groups'
            );

            routes.ADMIN.VAULT = setChildRouteConst(
                routes.ADMIN.DASHBOARD, 'vault', '/vault'
            );
        }

        function initMFGRouteConsts(routes) {
            routes.MFG = {};

            routes.MFG.MAIN = setRouteConst(
                mfgPrefix,
                mfgUrlPrefix
            );

            //dashboard
            routes.MFG.DASHBOARD = setChildRouteConst(
                routes.MFG.MAIN, 'dashboard', '/dashboard'
            );

            routes.MFG.LICENSING = setChildRouteConst(
                routes.MFG.MAIN, 'licensing', '/licensing'
            );

            routes.MFG.ROGUE = setChildRouteConst(
                routes.MFG.MAIN, 'rogue', '/rogue'
            );

            routes.MFG.MANAGELICENSE = setChildRouteConst(
                routes.MFG.MAIN, 'manageLicense', '/manageLicenses'
            );
        }

        function initLinkRouteConsts(routes) {
            routes.LINK = {};

            routes.LINK.MAIN = setRouteConst(
                linkPrefix,
                linkUrlPrefix
            );

            //dashboard
            routes.LINK.DASHBOARD = setChildRouteConst(
                routes.LINK.MAIN, 'dashboard', '/dashboard'
            );

        }

        function setRouteConst(stateName, url, fullUrl) {
            return {
                stateName: stateName,
                url: url,
                fullUrl: (typeof fullUrl === "undefined") ? url : fullUrl
            };
        }

        function setChildRouteConst(parent, stateName, url) {
            return setRouteConst(
                parent.stateName + '.' + stateName,
                url,
                parent.fullUrl + url

            );
        }

        return routes;
    }
})();
